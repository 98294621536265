import defaultEN from './default_en.json'
import defaultFR from './default_fr.json'
import defaultES from './default_es.json'
import defaultDE from './default_de.json'
import defaultPT from './default_pt.json'
import defaultIT from './default_it.json'
// Simplified Chinese
import defaultZHCN from './default_zh_cn.json'
// Traditional Chinese
import defaultZHTW from './default_zh_tw.json'
export default {
  en_GB: defaultEN,
  en_US: defaultEN,
  fr_CA: defaultFR,
  fr_FR: defaultFR,
  es_ES: defaultES,
  de_DE: defaultDE,
  pt_PT: defaultPT,
  zh_CN: defaultZHCN,
  zh_HK: defaultZHTW,
  zh_TW: defaultZHTW,
  it_IT: defaultIT
}
